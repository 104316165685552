// Step 1: Import React
import * as React from 'react'
import Layout from "../components/layout";
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

// Step 2: Define your component
const AboutPage = () => {
  return (
    <Layout pageTitle="About Me">
      <p>Hi there! I'm the proud creator of this site, which I built with Gatsby.</p>
    </Layout>
  )
}


const query = graphql`
query {
  mdx(frontmatter: {title: {eq: "author"}}) {
    frontmatter {
      title
    }
    body
  }
}
`;

// Step 3: Export your component
export default AboutPage

